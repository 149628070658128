<template>
  <div>
    <router-view />
    <van-tabbar route>
      <van-tabbar-item replace to="/content/total" icon="wap-home-o">总览</van-tabbar-item>
      <van-tabbar-item replace to="/content/monitor" icon="eye-o">监控</van-tabbar-item>
      <van-tabbar-item replace to="/content/chartExplore" icon="chart-trending-o">数据</van-tabbar-item>
      <van-tabbar-item replace to="/content/my" icon="manager-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "index",
  methods: {
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>
